
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getComponent, getConfigEnv } from '@/utils/helpers';

import TasqDetailsMixin from '@/components/tasqsCommon/TasqDetailsMixin';
import tasqsListModule from '@/store/modules/tasqsListModule';
import GenericMixin from '@/lib/mixins/GenericMixin';
import assetsModule from '@/store/modules/assetsModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModuleV2 from '@/store/modules/tasqProductionDataChartModuleV2';
import padSignalsModule from '@/store/modules/padSignalsModule';

// @ts-ignore
@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqDetails: () => getComponent('tasqs/TasqDetailV2'),
    WorkTicketDetail: () => getComponent('workTickets/WorkTicketDetail'),
    AddWorkTicket: () => getComponent('workTickets/AddWorkTicket'),
    ActionBubble: () => getComponent('tasqs/ActionBubble'),
   TasqAiPopup: () => getComponent('gpt/TasqAIPopup'),
  },
})
export default class TasqsDesktop extends mixins(
  TasqDetailsMixin,
  GenericMixin,
) {
  showAddWorkTicket = false;

  showAiPoupup = false

  showAiIntialPopup = false

  screenWidth = window.innerWidth;

  get activeWorkTicket() {
    return (
      tasqsListModule.activeWorkTicket
      || (this.$route.params.id && this.$route.query.type === 'workticket')
    );
  }

  get gridStyles() {
    if (
      !this.activeTasqExist
      && !this.activeWorkTicket
      && this.isVerticalListUnPinned
      && this.isVerticalListHovering
    ) {
      return 'relative flex';
    }
    if (this.isVerticalListUnPinned && this.isVerticalListHovering) {
      return 'relative grid grid-rows-1';
    }
    if (this.isVerticalListUnPinned && !this.isVerticalListHovering) {
      return 'overlay-vertical-miniview';
    }
    return 'overlay-vertical-unpin';
  }

  get activeTasqExist() {
    if (this.isEditing && !this.isBatchResponding) {
      return false;
    }
    return (
      tasqsListModule.activeTasq
      || (this.$route.params.id
        && this.$route.query.type !== 'workticket'
        && (this.$route.query.view === 'tasq'
          || this.$route.query.view === 'wellview'))
    );
  }

  get iconSize() {
    return this.screenWidth > 1279 ? 24 : 16;
  }

  handleResize() {
    this.screenWidth = window.innerWidth;
  }

  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  searchAiQuery = ''

  navigateToTasqAi(event = null) {
    if (event && event.key && event.key === 'Enter') event.preventDefault();
    if (this.searchAiQuery.length < 3) return;
    if (event && event.key && event.key !== 'Enter') return;

    this.$router.push({
        name: 'TasqAi',
        query: { query: this.searchAiQuery },
      });
      this.searchAiQuery = '';
  }

  async created() {
    tasqsListModule.setIsMobileView(false);
    await this.loadProducingWellData();
    await this.initializeTasqDetails();
  }

  mouseOver() {
    this.showAiIntialPopup = true;
    this.setFocus();
  }

  setFocus() {
    this.$nextTick(() => {
      if (this.$refs.gptPromptTextarea) {
        (this.$refs.gptPromptTextarea as any).focus();
      }
    });

    this.$nextTick(() => {
      if (this.$refs.gptPromptTextarea) {
        (this.$refs.gptPromptTextarea as any).focus();
      }
    });
  }

  mouseLeave() {
    if (this.searchAiQuery.length > 1) return;
    this.showAiIntialPopup = false;
  }

  adjustTextareaHeight() {
    this.$nextTick(() => {
      //  @ts-ignore
        const textarea = this.$refs.gptPromptTextarea.$el;
        if (textarea.scrollHeight < 50) return;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      });
    }

  @Watch('$route.params.id', { immediate: true })
  async onTasqRouteIdChange(id: string) {
    tasqSignalsModule.setAxiosSignalController('abort');
    // tasqSignalsModule.setAxiosPlungerController('abort');
    tasqSignalsModule.setPlungerController('abort');
    tasqSignalsModule.setSignalController('abort');

    // const { wellName } = this.$route.query;
    if (this.isLoadingFirstTimeDone) {
      if (id) {
        assetsModule.setActiveTasqID(id);
        this.showDataLoadingFeedback = true;

        tasqsListModule.setTasqDataLoadingFeedback(true);

        this.fetchTasq().then(async () => {
          tasqsListModule.setTasqDataLoadingFeedback(false);
          await this.sleep(300);
          tasqsListModule.updateTasqViewsCount({ predictionID: id });
        });

        this.padWellsSkipToBeginning();
      } else {
        tasqsListModule.setActiveTasq('');
      }
      this.multiTasqType = '';
    }
  }
}
